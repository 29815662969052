// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
global.$ = require('jquery')

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@fortawesome/fontawesome-free/css/all"
import 'bootstrap-table/dist/bootstrap-table'
import 'bootstrap-table/dist/extensions/cookie/bootstrap-table-cookie'
import 'bootstrap-table/dist/extensions/page-jump-to/bootstrap-table-page-jump-to'
import 'bootstrap'

import 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.css'

import 'bootstrap-datepicker/dist/js/bootstrap-datepicker'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css'


Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.Rails = Rails;
window.jQuery = $;
window.$ = $;
import toastr from 'toastr'

window.toastr = toastr
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import SignaturePad from 'signature_pad'

window.Swal = Swal;
window.SignaturePad = SignaturePad;

window.addEventListener('DOMContentLoaded', () => {
    $('.content-search').select2();
    $('.table-regular').bootstrapTable();
    $('.date-pick').datepicker({
        language: "pl",
        format: "dd/mm/yyyy",
        todayHighlight: true
    });
});


$(document).ready(function () {

    $('.table-regular').bootstrapTable();
    $('.date-pick').datepicker({
        language: "pl",
        format: "dd/mm/yyyy",
        todayHighlight: true
    });

    $('.send-form-after-change').change(function () {
        $(this).parents('form').submit();
    });

    $('.form-remove-empty-get-params').submit(function () {
        $(this).find('input, select').each(function () {
            var input = $(this);
            if (!input.val())
                input.attr('name', '');
        });
    });

    let options = [];

    $('.dropdown-menu a').on('click', function (event) {

        let $target = $(event.currentTarget),
            val = $target.attr('data-value'),
            $inp = $target.find('input'),
            idx;

        if ((idx = options.indexOf(val)) > -1) {
            options.splice(idx, 1);
            setTimeout(function () {
                $inp.prop('checked', false)
            }, 0);
        } else {
            options.push(val);
            setTimeout(function () {
                $inp.prop('checked', true)
            }, 0);
        }

        $(event.target).blur();

        console.log(options);
        return false;
    });


});



